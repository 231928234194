<template>
  <div class="basis">
    <div class="box">
      <el-form
        ref="basicParams"
        :model="basicParams"
        label-width="200px"
        label-position="left"
      >
        <el-form-item label="门店名称">
          <el-input v-model="basicParams.StoresName" size="small"></el-input>
        </el-form-item>
        <!--  -->
        <el-form-item label="店铺地址">
          <!-- <el-cascader
            v-model="cascaderValue"
            :options="optionsData"
            @change="handleChange"
            size="small"
            placeholder="请选择地址"
          ></el-cascader>
          <el-input
            type="textarea"
            :rows="5"
            v-model="basicParams.StoresAddress"
            class="textarea"
            placeholder="请输入详情地址"
            @blur="onBlurAddress"
          ></el-input>
          <el-input style="margin-top: 20px;" v-model="basicParams.Position" size="small" placeholder="地理位置精准坐标(可选)"></el-input>
          <div id="map"></div>
          <div class="refresh" @click="onRefresh">
            <i class="el-icon-refresh"></i>
            <span>刷新地图</span>
          </div> -->
          <el-input
            type="textarea"
            :rows="5"
            v-model="basicParams.StoresAddress"
            class="textarea"
            placeholder="请输入详情地址"
            @focus="onShowMap"
          ></el-input>
          <el-input v-model="basicParams.Position" placeholder="" size="mini" @focus="onShowMap"></el-input>
        </el-form-item>
        <!--  -->
        <el-form-item label="联系电话">
          <el-input
            v-model="basicParams.StoresPhone"
            size="small"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <!--  -->
        <el-form-item label="营业时间">
          <el-input
            v-model="basicParams.WorkTime"
            size="small"
            placeholder="请输入营业时间"
          ></el-input>
        </el-form-item>
        <!--  -->
        <el-form-item label="员工称谓">
          <p>*可自定义小程序上，员工的显示称谓，最多五个字</p>
          <el-row>
            <!-- <el-col :span="3">预约</el-col> -->
            <el-col :span="24">
              <el-input
                v-model="basicParams.WxmpEmplAlias"
                size="small"
                placeholder="请输入预约员工称谓"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <!--  -->
        <el-form-item label="门店logo">
          <div class="logo">
            <el-upload
              class="upload-demo"
              :action="uploadLogoUrl"
              :limit="1"
              :headers="headers"
              :on-success="onUploadSuccessLogo"
              :on-error="onUploadError"
              :before-upload="beforeAvatarUpload"
              :on-exceed="onUploadExceed"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过500kb
              </div>
            </el-upload>
            <div class="qrcode">
              <el-image
                v-if="basicParams.WxmpCoverUrl"
                :src="basicParams.WxmpCoverUrl"
              ></el-image>
              <div class="bottom">
                <i class="el-icon-delete" @click="onDeleteLogo"></i>
              </div>
            </div>
          </div>
        </el-form-item>
        <!--  -->
        <el-form-item label="小程序码">
          <div class="code">
            <el-upload
              class="upload-demo"
              :action="uploadCodeUrl"
              :limit="1"
              :headers="headers"
              :on-success="onUploadSuccessCode"
              :on-error="onUploadError"
              :before-upload="beforeAvatarUpload"
              :on-exceed="onUploadExceed"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过500kb
              </div>
            </el-upload>
            <div class="qrcode">
              <el-image
                v-if="basicParams.WxmpQrcodeUrl"
                :src="basicParams.WxmpQrcodeUrl"
              ></el-image>
              <div class="bottom">
                <i class="el-icon-delete" @click="onDeleteCode"></i>
              </div>
            </div>
          </div>
        </el-form-item>
        <!--  -->
        <el-form-item label="显示菜单">
          <p>
            *该设置为小程序底部菜单栏显示，拖拽菜单可改变顺序，最多可勾选4个
          </p>
          <p>
            *自定义标题为显示在小程序上的菜单名称，可自定义，仅支持中英文、空格和数字，字数不超过5个汉字或10个字母/数字，空格不能为首末位。
          </p>
          <div class="menu">
            <div class="tarber">
              <div>默认菜单</div>
              <div>自定义菜单</div>
            </div>
            <div class="list" id="tarber">
              <div
                class="tarber"
                v-for="(item, index) in defaultTabbarItems"
                :key="index"
              >
                <div class="top">
                  <!-- isCheckboxTabber(item,index) -->
                  <el-checkbox
                    :value="item.selected"
                    @change="onCheckboxTabber($event, item)"
                    >{{ item.Name }}</el-checkbox
                  >
                </div>
                <div class="bottom">{{ item.ActualName }}</div>
              </div>
            </div>
          </div>
        </el-form-item>
        <!--  -->
        <el-form-item label="营销活动">
          <p>*该设置为小程序首页营销活动展示，拖拽菜单可改变顺序</p>
          <div class="market" id="market">
            <div
              class="market-action"
              v-for="(item, index) in defaultHomeLinks"
              :key="index"
            >
              <!-- isCheckbox(item,index) -->
              <el-checkbox
                :checked="item.selected"
                @change="onCheckbox($event, item)"
                >{{ item.Name }}</el-checkbox
              >
            </div>
          </div>
        </el-form-item>
        <!--  -->
        <el-form-item label="首页功能模块" v-if="basicParams.WxmpHomeZones">
          <el-switch
            v-model="basicParams.WxmpHomeZones.PopularEmpl"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
          <span>*员工介绍模块</span>
          <el-switch
            v-model="basicParams.WxmpHomeZones.PopularItem"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
          <span>*项目介绍模块</span>
        </el-form-item>
        <!--  -->
        <el-form-item label="显示员工工号">
          <el-switch
            v-model="basicParams.WxmpShowEmplNo"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
          <p>*打开即小程序中员工头像显示工号，关闭即不显示工号</p>
        </el-form-item>
        <!--  -->
        <el-form-item label="切换门店">
          <el-switch
            v-model="basicParams.WxmpShowChain"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <!-- 选择助理  -->
        <el-form-item label="顾客自助消费时不需要选择助理">
          <el-switch
            v-model="basicParams.CsrEmptyAssistant"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
          <!-- <p>*</p> -->
        </el-form-item>
        <!-- 选择发型师  -->
        <el-form-item label="顾客自助消费时不需要选择发型师">
          <el-switch
            v-model="basicParams.CsrEmptyMaster"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
          <!-- <p>*</p> -->
        </el-form-item>
        <!-- 员卡支付 -->
        <el-form-item label="顾客自助消费时不使用会员卡支付">
          <el-switch
            v-model="basicParams.CsrForbidCardPay"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
          <!-- <p>*</p> -->
        </el-form-item>
        <!-- 消费页面项目列表 -->
        <el-form-item label="项目列表精简样式">
          <el-switch
            v-model="basicParams.WxmpItemListStyle"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
          <!-- <p>*</p> -->
        </el-form-item>
        <!--  -->
        <el-form-item label="显示员工评价星级">
          <el-switch
            v-model="basicParams.WxmpShowEmplStar"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <!--  -->
        <el-form-item label="顾客可在线开卡">
          <el-switch
            v-model="basicParams.CsrAllowBuyCard"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <!--  -->
        <el-form-item label="顾客只能从次曰开始进行预约">
          <el-switch
            v-model="basicParams.RsrTomorrowStart"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <!--  -->
        <el-form-item label="会员卡过期继续显示">
          <el-switch
            v-model="basicParams.CsrShowExpiredVip"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <!--  -->
        <el-form-item label="显示储值卡余额">
          <el-switch
            v-model="basicParams.WxmpShowVipBalance"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <!--  -->
        <el-form-item label="显示赠送项目">
          <el-switch
            v-model="basicParams.WxmpShowVipDonaItems"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <!--  -->
        <el-form-item label="显示我的会员卡">
          <el-radio-group v-model="basicParams.WxmpShowVipNature">
            <el-radio :label="0" border>全部显示</el-radio>
            <el-radio :label="1" border>显示储值卡</el-radio>
            <el-radio :label="2" border>显示疗程卡</el-radio>
          </el-radio-group>
        </el-form-item>
        <!--  -->
        <el-form-item label="疗程卡不显示产品">
          <el-switch
            v-model="basicParams.WxmpHideVipPackPro"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <!--  -->
        <el-form-item label="是否显示交易历史">
          <el-switch
            v-model="basicParams.WxmpShowTransHistory"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <!--  -->
        <el-form-item label="预约时间段">
          <el-input
            v-model="basicParams.RsrReserveHours"
            size="small"
            placeholder="请输入预约时间段"
          ></el-input>
          <p>*预约时间段格式：08:00-14:00,16:00-22:00</p>
        </el-form-item>
        <!--  -->
        <el-form-item label="预约时间间隔">
          <el-input
            v-model="basicParams.RsrReserveInterval"
            size="small"
            placeholder="请输入时间间隔"
          ></el-input>
          <p>*时间间隔单位：分钟</p>
        </el-form-item>
        <!--  -->
        <el-form-item label="预约优先模式">
          <el-radio-group v-model="basicParams.RsrFirstMode">
            <el-radio v-for="(item, index) in rsrFirstModeList" :key="index" :label="item.Value" border>{{ item.Name }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <!--  -->
        <el-form-item label="轮播图">
          <div>
            <el-upload
              class="upload-demo"
              :multiple="true"
              :action="uploadSwiperUrl"
              :limit="3"
              :headers="headers"
              :on-success="onUploadSuccessSwiper"
              :on-error="onUploadError"
              :before-upload="beforeAvatarUpload"
              :on-exceed="onUploadExceed"
              :on-remove="onUploadRemove"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </div>
          <p>
            建议 375*325 像素，只能上传 jpg/png 文件，最多3张。拖拽图片可排序
          </p>
          <div id="swiper" class="swiper">
            <div v-for="(item, index) in swiperData" :key="index" class="back">
              <el-image
                :src="item"
                :preview-src-list="swiperData"
                v-if="item"
              ></el-image>
              <div class="bottom">
                <i class="el-icon-delete" @click="onDeleteWwiper(index)"></i>
              </div>
            </div>
          </div>
        </el-form-item>
        <!--  -->
        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="small"
            >保存</el-button
          >
        </el-form-item>
        <!--  -->
      </el-form>
    </div>

    <DfWxmapDialog ref="DfWxmapDialog" @success="onSelectAddress"></DfWxmapDialog>
  </div>
</template>

<script>
import api from "@/api/setting.js";
import { formatTimeQuantum } from "@/utils/utils.js";
import { baseURL } from "@/utils/axios.js";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
export default {
  name: "basis",
  data() {
    return {
      basicParams: {},
      defaultHomeLinks: [],
      defaultTabbarItems: [],
      optionsData: regionData,
      cascaderValue: "",
      uploadLogoUrl: baseURL + `/setting/wxmp_basic?subact=upload_cover`,
      uploadCodeUrl: baseURL + `/setting/wxmp_basic?subact=upload_qrcode`,
      uploadSwiperUrl: baseURL + `/setting/wxmp_basic?subact=upload_carousel`,
      headers: {
        acctoken: window.sessionStorage.getItem("token"),
      },
      swiperData: [],
      swiperCarouselData: [],
      rsrFirstModeList: [],
      isTabbarSeveralTimes: 1,
      csrModule: 0,
    };
  },
  created() {
    this.queryData();
  },
  mounted() {
    this.tabbarSortable();
    this.marketSortable();
    this.swiperSortable();
  },
  methods: {
    // 初始化数据
    async queryData() {
      try {
        let { data } = await api.wxmpBasic({}, "init_data");
        let {
          basicParams,
          defaultHomeLinks,
          defaultTabbarItems,
          csrModule,
          rsrFirstModeList
        } = data;
        this.isCheckboxSelect(basicParams.WxmpHomeLinks, defaultHomeLinks);
        this.basicParams = data.basicParams;
        this.defaultHomeLinks = defaultHomeLinks;
        this.isCheckboxSelect(basicParams.WxmpTabbarItems, defaultTabbarItems);
        this.defaultTabbarItems = defaultTabbarItems;
        this.swiperData = this.basicParams.WxmpCarouselUrls;
        this.swiperCarouselData = this.basicParams.WxmpCarousels;
        this.csrModule = csrModule;
        this.rsrFirstModeList = rsrFirstModeList
        // let position = [];
        // if (
        //   this.basicParams.Position !== null ||
        //   this.basicParams.Position !== ""
        // ) {
        //   position = this.basicParams.Position.split(",");
        // }
        // setTimeout(() => {
        //   this.requestGeographicPosition(position[0], position[1]);
        // }, 1000);
      } catch (error) {
        this.$message.error("请求失败，重新刷新浏览器。");
      }
    },

    // 默认给数据添加状态
    isCheckboxSelect(arr1, arr2) {
      arr1.forEach((item, index) => {
        arr2.forEach((item2, index2) => {
          if (item.Code == item2.Code) {
            item2["selected"] = true;
          }
        });
      });
    },

    // 店铺地址事件
    handleChange(event) {
      let address =
        CodeToText[event[0]] + CodeToText[event[1]] + CodeToText[event[2]];
      this.basicParams.StoresAddress = address;
      this.onBlurAddress();
    },

    // 详情地址事件
    onBlurAddress(event) {
      //let http = `/api/ws/geocoder/v1/?address=${this.basicParams.StoresAddress}&key=UL2BZ-4Z5WD-ONP44-HG7SB-5NJBH-TSB75`;
      this.$jsonp("https://apis.map.qq.com/ws/geocoder/v1", {
        key: "UL2BZ-4Z5WD-ONP44-HG7SB-5NJBH-TSB75",
        address: this.basicParams.StoresAddress,
        output: "jsonp",
      })
        .then((res) => {
          let { result } = res;
          this.basicParams.Position =
            result.location.lat + "," + result.location.lng;

          this.requestGeographicPosition(
            result.location.lat,
            result.location.lng
          );
        })
        .catch((err) => {
          this.$message.error("获取地图失败");
        });
    },

    onSelectAddress(event){
      this.basicParams.StoresAddress = event.StoresAddress;
      this.basicParams.Position = event.Position;
    },

    // 上传logo成功
    onUploadSuccessLogo(event) {
      let { uri, url } = event.data;
      this.basicParams.WxmpCover = uri;
      this.basicParams.WxmpCoverUrl = url;
    },
    // 上传小程序二维码成功
    onUploadSuccessCode(event) {
      let { uri, url } = event.data;
      this.basicParams.WxmpQrcode = uri;
      this.basicParams.WxmpQrcodeUrl = url;
    },
    // 上传轮播图成功
    onUploadSuccessSwiper(event) {
      this.swiperData.push(event.data.url);
      this.swiperCarouselData.push(event.data.uri);
      console.log(this.swiperData, this.swiperCarouselData);
    },
    // 上传失败 (共有的)
    onUploadError() {
      this.$message.error("上传图片失败!");
    },
    // 上传文件限制事件 (共有的)
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isPNG = file.type === "image/png";
      const isBMP = file.type === "image/bmp";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isGIF && !isPNG && !isBMP) {
        this.$message.error("上传图片必须是JPG/GIF/PNG/BMP 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return (isJPG || isBMP || isGIF || isPNG) && isLt2M;
    },
    // 上传文件超出数量 (共有的)
    onUploadExceed(event) {
      this.$message.error("上传文件超出数量!");
    },
    // 删除上传文件
    onUploadRemove(event) {
      let index = this.swiperData.findIndex((item) => {
        return item.url == event.response.data.url;
      });
      this.swiperData.splice(index, 1);
    },
    // 删除logo
    onDeleteLogo() {
      this.basicParams.WxmpCover = "";
      this.basicParams.WxmpCoverUrl = "";
    },
    // 删除小程序码
    onDeleteCode() {
      this.basicParams.WxmpQrcode = "";
      this.basicParams.WxmpQrcodeUrl = "";
    },
    // 删除轮播图
    onDeleteWwiper(index) {
      this.swiperData.splice(index, 1);
      this.swiperCarouselData.splice(index, 1);
    },
    // tabbar 是否 被选中
    isCheckboxTabber(item, item_index) {
      let index = this.basicParams.WxmpTabbarItems.findIndex((value) => {
        return value.Code == item.Code;
      });
      let selected = index !== -1 ? true : false;
      this.defaultTabbarItems[item_index]["selected"] = selected;
      return selected;
    },
    // tabbar 点击事件
    onCheckboxTabber(event, item) {
      let index = this.defaultTabbarItems.findIndex((value, i) => {
        return value.Code == item.Code;
      });
      if (item.Code == "CSR" && !this.csrModule) {
        this.$message.info("您未购买【消费】功能");
        this.defaultTabbarItems[index]["selected"] = false;
      } else {
        this.defaultTabbarItems[index]["selected"] = event;
      }

      // console.log(this.defaultTabbarItems);
    },

    onShowMap(){
      this.$refs.DfWxmapDialog.onShowDialog(this.basicParams)
    },

    // 营销活动 点击事件
    onCheckbox(event, item) {
      let index = this.defaultHomeLinks.findIndex((value) => {
        return value.Code == item.Code;
      });
      this.defaultHomeLinks[index]["selected"] = event;
    },
    // 营销活动 Checkbox 是否 被选中
    isCheckbox(item, item_index) {
      let index = this.basicParams.WxmpHomeLinks.findIndex((value) => {
        return value.Code == item.Code;
      });
      let selected = index !== -1 ? true : false;
      this.defaultHomeLinks[item_index]["selected"] = selected;
      return selected;
    },
    // 营销活动 拖动
    marketSortable() {
      let market = document.getElementById("market");
      let sortable = new this.Sortable(market, {
        animation: 1000,
        chosenClass: "sortable-chosen",
        dragClass: "sortable-drag",
        onEnd: (event) => {
          let { newIndex, oldIndex } = event;
          let temporaryArray = this.defaultHomeLinks.slice(0);
          let item = temporaryArray.splice(oldIndex, 1);
          temporaryArray.splice(newIndex, 0, ...item);
          this.defaultHomeLinks = [];
          this.$nextTick(() => {
            this.defaultHomeLinks = temporaryArray;
          });
        },
      });
    },

    // tabbar 拖动
    tabbarSortable() {
      let tarber = document.getElementById("tarber");
      new this.Sortable(tarber, {
        animation: 1000,
        onEnd: (event) => {
          let { newIndex, oldIndex } = event;
          let temporaryArray = this.defaultTabbarItems.slice(0);
          let item = temporaryArray.splice(oldIndex, 1);
          temporaryArray.splice(newIndex, 0, ...item);
          this.defaultTabbarItems = [];
          this.$nextTick(() => {
            this.defaultTabbarItems = temporaryArray;
            console.log(this.defaultTabbarItems);
          });
        },
      });
    },

    // 轮播图拖动
    swiperSortable() {
      let swiper = document.getElementById("swiper");
      let sortable = new this.Sortable(swiper, {
        animation: 1000,
        onEnd: (event) => {
          let { newIndex, oldIndex } = event;
          // swiperData
          let temporaryArray = this.swiperData.slice(0);
          let item = temporaryArray.splice(oldIndex, 1);
          temporaryArray.splice(newIndex, 0, ...item);
          this.swiperData = [];
          // swiperCarouselData
          let temporaryArray2 = this.swiperCarouselData.slice(0);
          let item2 = temporaryArray2.splice(oldIndex, 1);
          temporaryArray2.splice(newIndex, 0, ...item2);
          this.swiperCarouselData = [];
          //
          this.$nextTick(() => {
            this.swiperData = temporaryArray;
            this.swiperCarouselData = temporaryArray2;
            console.log(this.swiperData, this.swiperCarouselData);
          });
        },
      });
    },
    onRefresh() {
      let position = [];
      if (
        this.basicParams.Position !== null ||
        this.basicParams.Position !== ""
      ) {
        position = this.basicParams.Position.split(",");
        console.log(position[0], position[1]);
        this.requestGeographicPosition(position[0], position[1]);
      }
    },
    // 设置地图
    requestGeographicPosition(position_lat, position_lng) {
      //let http = `https://apis.map.qq.com/ws/geocoder/v1/?address=`

      let then = this;
      document.getElementById("map").innerHTML = "";
      //前端定位组件
      var geolocation = new qq.maps.Geolocation(
        "UL2BZ-4Z5WD-ONP44-HG7SB-5NJBH-TSB75",
        "DiFengSoft"
      );
      var options = { timeout: 8000 };
      //获取当前所在地理位置
      geolocation.getLocation(
        (position) => {
          let lat;
          let lng;
          if (position_lat && position_lng) {
            lat = position_lat;
            lng = position_lng;
          } else {
            lat = position.lat;
            lng = position.lng;
          }
          // 定义地图中心点坐标
          var center = new window.TMap.LatLng(lat, lng);
          //创建map对象，初始化地图
          var map = new window.TMap.Map(document.getElementById("map"), {
            center: center, //设置地图中心点坐标
            zoom: 17.2, // 设置地图缩放级别
            pitch: 43.5, // 设置俯仰角
            rotation: 45, // 设置地图旋转角度
            viewMode: "2D", // 默认3D
          });
          //创建并初始化MultiMarker 点标记
          var markerLayer = new window.TMap.MultiMarker({
            map: map, //指定地图容器
            //样式定义
            styles: {
              //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
              myStyle: new window.TMap.MarkerStyle({
                width: 25, // 点标记样式宽度（像素）
                height: 35, // 点标记样式高度（像素）
                src:
                  "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png", //图片路径
                //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                anchor: { x: 16, y: 32 },
              }),
            },
            //点标记数据数组
            geometries: [
              {
                id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
                styleId: "myStyle", //指定样式id
                position: new window.TMap.LatLng(lat, lng), //点标记坐标位置
                properties: {
                  //自定义属性
                  title: "marker1",
                },
              },
            ],
          });
          //点击事件
          map.on("click", (event) => {
            console.log(event);
            then
              .$jsonp("https://apis.map.qq.com/ws/geocoder/v1", {
                key: "UL2BZ-4Z5WD-ONP44-HG7SB-5NJBH-TSB75",
                location: event.poi.latLng.lat + "," + event.poi.latLng.lng,
                output: "jsonp",
              })
              .then((res) => {
                let { result } = res;
                let { address, location } = result;
                then.basicParams.Position = location.lat + "," + location.lng;
                then.basicParams.StoresAddress = address;
                console.log(res);
              })
              .catch((err) => {
                this.$message.error("获取地图失败");
              });
            // let http = `/api/ws/geocoder/v1/?location=${event.poi.latLng.lat},${event.poi.latLng.lng}&key=UL2BZ-4Z5WD-ONP44-HG7SB-5NJBH-TSB75`;
            // axios.get(http).then(res =>{
            // 	let { data }  = res;
            // 	let { address, location} = data.result
            // 	then.basicParams.Position = location.lat + ',' + location.lng;
            // 	then.basicParams.StoresAddress = address;
            // 	console.log(then.basicParams.Position);
            // }).catch(function (error) {
            // 	console.log(error);
            // });
            markerLayer.updateGeometries([
              {
                id: "1",
                styleId: "myStyle",
                position: new window.TMap.LatLng(
                  event.poi.latLng.lat,
                  event.poi.latLng.lng
                ),
              },
            ]);
          });
        },
        function(err) {
          console.log(err);
        },
        options
      );
    },

    // 提交数据
    async onSubmit() {
      if (formatTimeQuantum(this.basicParams.RsrReserveHours) == false) {
        this.$message.error(
          "预约时间段--格式不正确，请输入正确的格式如：08:00-14:00,16:00-22:00。"
        );
        return;
      }

      this.basicParams.WxmpTabbarItems = [];
      this.basicParams.WxmpHomeLinks = [];
      this.basicParams.WxmpCarousels = this.swiperCarouselData;
      this.basicParams.WxmpCarouselUrls = this.swiperData;
      this.defaultHomeLinks.forEach((item, index) => {
        if (item.selected !== undefined && item.selected == true) {
          this.basicParams.WxmpHomeLinks.push(item);
        }
      });

      this.defaultTabbarItems.forEach((item, index) => {
        if (item.selected !== undefined && item.selected == true) {
          this.basicParams.WxmpTabbarItems.push(item);
        }
      });

      try {
        let data = await api.wxmpBasic(this.basicParams, "save_data");
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.queryData();
      } catch (e) {
        this.$message.error("保存失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  display: flex;
  align-items: center;
  flex-direction: column;
  .el-form {
    width: 720px;
    margin-top: 20px;
    margin: 0 auto;
    .el-input {
      width: 100%;
    }
    .el-cascader {
      width: 100%;
    }
    .textarea {
      display: block;
      width: 100%;
      margin-top: 18px;
    }
    .logo,
    .code {
      display: flex;
      .qrcode {
        position: relative;
        .el-image {
          width: 150px;
          height: 150px;
          margin-left: 20px;
          border: 1px solid #eeeeee;
          border-radius: 5px;
        }
        .bottom {
          position: absolute;
          bottom: 15px;
          left: 20px;
          width: 150px;
          height: 150px;
          background-color: rgba(0, 0, 0, 0.3);
          display: none;
          border-radius: 5px;
          color: #ffffff;
          font-size: 30px;
          i {
            margin-top: 60px;
            margin-left: 60px;
          }
        }
      }
      .qrcode:hover .bottom,
      .qrcode:hover .bottom {
        display: block;
      }
    }

    #map {
      width: 100%;
      height: 250px;
      border: 1px solid #dcdfe6;
      margin-top: 20px;
    }
    .refresh {
      margin-top: 10px;
      cursor: pointer;
      i {
        font-size: 20px;
        font-weight: 400;
      }
    }
    .swiper {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      .back {
        position: relative;
        .el-image {
          margin-right: 15px;
          width: 240px;
          height: 150px;
          border-radius: 5px;
        }
        .bottom {
          position: absolute;
          bottom: 15px;
          left: 0;
          width: calc(100% - 15px);
          height: 50px;
          background-color: rgba(0, 0, 0, 0.3);
          border-radius: 5px;
          display: none;
          text-align: center;
          i {
            margin-top: 10px;
            font-size: 30px;
            color: #ffffff;
          }
        }
      }
      .back:hover .bottom {
        display: block;
      }
    }
  }
  p,
  span {
    color: #8492a6;
    line-height: 25px;
  }
  span {
    margin: 0 10px;
  }
  .menu {
    display: flex;
    margin-top: 20px;
    border-left: 1px solid #dcdfe6;
    .tarber {
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        width: 95px;
        border-right: 1px solid #dcdfe6;
      }
      > div:first-child {
        border-bottom: 1px solid #dcdfe6;
        border-top: 1px solid #dcdfe6;
      }
      > div:last-child {
        border-bottom: 1px solid #dcdfe6;
      }
    }
    .list {
      flex: 1;
      display: flex;
    }
  }
  .market {
    border: 1px solid #dcdfe6;
    display: flex;
    margin-top: 20px;
    .market-action {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 88px;
      flex: 1;
      border-right: 1px solid #dcdfe6;
      cursor: move;
    }
    .market-action:last-child {
      border-right: 0;
    }
    .sortable-chosen {
      background-color: #ededed;
    }
    .sortable-drag {
      background-color: #ededed;
    }
  }
}
</style>
